import React from 'react';
export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header2-logo',
    children: require('../img/logo.png'),
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav',
        to: 'Banner1_0',
        children: '首页',
        className: 'menu-item',
      },
      {
        name: 'linkNav~ku9oxsfrrd',
        to: 'Feature1_0',
        children: '产品服务',
        className: 'menu-item',
      },
      {
        name: 'linkNav~ku9oxxbz0vc',
        to: '#',
        children: '系统平台',
        className: 'menu-item',
      },
      {
        name: 'linkNav~ku9oy24bbsm',
        to: 'Content11_0',
        children: '关于我们',
        className: 'menu-item',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Banner10DataSource = {
  wrapper: { className: 'banner1' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg0' },
        title: {
          className: 'banner1-title',
        },
        content: {
          className: 'banner1-content',
          children: (
            <span>
              让互联更智能<br />
              <span>Making links samrter</span>
            </span>
          ),
        },
        button: {
          className: 'banner1-button',
        },
      },
      {
        name: 'elem1',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg1' },
        title: {
          className: 'banner1-title',
        },
        content: {
          className: 'banner1-content',
          children: (
            <span>
              智能化、可视化平台<br />
              <span>Smart and Visual system</span>
            </span>
          ),
        },
        button: { className: 'banner1-button', },
      },
      {
        name: 'elem2',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg2' },
        title: {
          className: 'banner1-title',
        },
        content: {
          className: 'banner1-content',
          children: (
            <span>
              安全、高效设备管理<br />
              <span>Safe and Efficient Management system</span>
            </span>
          ),
        },
        button: { className: 'banner1-button', },
      },
    ],
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '我们的服务',
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 6,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              require('../img/icon01.png'),
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '安全可靠' },
          content: {
            className: 'content3-content',
            children:
              '数据加密、车辆防盗',
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 6,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              require('../img/icon02.png'),
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '电气系统智能化' },
          content: {
            className: 'content3-content',
            children:
              '无钥匙启动、自动设防、限速警告等',
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 6,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              require('../img/icon03.png'),
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '管理可视化' },
          content: {
            className: 'content3-content',
            children:
              '位置、轨迹、状态、电池状态等可视化',
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 6,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              require('../img/icon04.png'),
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '商业便捷化' },
          content: {
            className: 'content3-content',
            children:
              '在线租赁、支付，低成本智能化管理',
          },
        },
      },
    ],
  },
};
export const Content40DataSource = {
  wrapper: { className: 'home-page-wrapper content4-wrapper' },
  page: { className: 'home-page content4' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>一站式解决方案</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content content4-title-content',
        children: (
          <span>
            <p>
              智伟科讯开发的物联网终端作为智慧出行通讯入口，将电摩、电动车、电单车的运行状态（位置、电量、里程、故障、报警信息等）上传到云端服务器，经过即时分析运算得出可视化数据并通过APP实时反馈给用户的同时，平台呈现的可视化报表可供管理者监控、分析和控制，助力电动车智能化管理，实现人、车、云互联。
            </p>
          </span>
        ),
      },
    ],
  },
};
export const Feature00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '终端设备' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                require('../img/pic8.png'),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>共享电单车ECU</span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>4G通讯 异常报警</p>
                  <p>多重定位 高精度定位</p>
                  <p>485通信 P67防水</p>
                  <p>蓝牙通讯 断电保护</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: require('../img/pic9.png'),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>电池管理终端</span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>多重定位 实时监测</p>
                      <p>亏电保护 远程控制</p>
                      <p>蓝牙通讯 防拆报警</p>
                      <p>数据透传 内置天线</p>
                      <p>温度监测 远程升级</p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                require('../img/pic10.png'),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>电动车ECU中控</span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>实时定位 远程控制</p>
                      <p>震动报警 超速提醒</p>
                      <p>温度检测 智能锁控</p>
                      <p>轮动报警 音频提醒</p>
                      <p>数据采集 断电保护</p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper hbp002' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper title-mt',
    children: '产品服务',
  },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://gw.alipayobjects.com/os/c/cms/images/17hvco9uioxs/14909ced-a0cb-45ca-8ac6-948e2da7d156_w1024_h682.jpg',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <span>
          <p>共享租赁服务</p>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <span>
          <span>
            <p>
              合作商家和用户可通过系统管理平台实现设备定位管理、商业租赁支付、运营整体可视化管理，为用户完善和不断改进用户体验提供营运数据支撑。<br />
            </p>
          </span>
        </span>
      </span>
    ),
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: 'https://gw.alipayobjects.com/os/c/cms/images/17hvco9uioxs/14909ced-a0cb-45ca-8ac6-948e2da7d156_w1024_h682.jpg',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <p>电池状态监测系统</p>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <p>
          电池状态数据系统为用户直观展现动力电池的运行核心数据，提高电池的利用率，防止电池过度充放电，实时监测电池的使用温度，为保障电池安全、稳定、高效的使用提供数据保障。
        </p>
      </span>
    ),
  },
};
export const Feature11DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: '',
  },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://gw.alipayobjects.com/os/c/cms/images/17hvco9uioxs/14909ced-a0cb-45ca-8ac6-948e2da7d156_w1024_h682.jpg',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>设备位置管理系统</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>
          使用GPS+4G二合一通信模块实现设备的定位管理、轨迹记录和里程计算等功能，为用户的租赁和设备管理等商业活动提供必须的数据服务。
        </p>
      </span>
    ),
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>关于我们</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>
              无锡智伟科讯科技有限公司是一家以位置服务和平台设备管理为核心的科技公司。公司以IoT设备和平台服务为基础，综合运用智能传感、物联网、大数据和移动互联网的技术手段，为客户提供包含硬件开发、平台开发与部署、设备资产管理、远程监控等整体解决方案。
            </p>
          </span>
        ),
        className: 'title-content ku9oqddarw-editor_css',
      },
      {
        name: 'content2',
        children: (
          <span>
            <p>
              公司创立于2021年，座落于无锡530科技大厦。我们拥有一支10余年软件和硬件产品研发经验的工程师团队。专致、高效、诚信、创新是企业的宗旨。我们致力于通讯位置服务和设备管理市场应用持续赋能，积极参与传统行业数字化转型，在积极创造社会价值中实现自身价值。
            </p>
          </span>
        ),
        className: 'title-content ku9oqddarw-editor_css',
      },
    ],
  },
};
export const Contact00DataSource = {
  wrapper: { className: 'home-page-wrapper content10-wrapper' },
  Content: {
    className: 'icon-wrapper',
    children: {
      icon: {
        className: 'icon',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/zIUVomgdcKEKcnnQdOzw.svg',
        name: '主要图标',
      },
      iconShadow: {
        className: 'icon-shadow',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/WIePwurYppfVvDNASZRN.svg',
        name: '图标影阴',
      },
      url: { children: 'https://gaode.com/place/B01FE19UX3', name: '跳转地址' },
      title: { children: '联系我们', name: '弹框标题' },
      content: {
        children: '无锡市新吴区清源路18号530大厦C栋206室',
        name: '弹框内容',
      },
    },
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright custormEdit',
    children: (
      <span>
        地址：无锡市新吴区清源路18号530大厦C栋206室<br />电话：13915326066<br />©2018 <a href="http://#">智伟科讯</a> All Rights
        Reserved.
      </span >
    ),
  },
  img: {
    children: require('../img/qrcode.jpg')
  }
};